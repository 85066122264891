.login-logo {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    border: 1px solid var(--primary);
    overflow: hidden;
    margin: 10px;
}

.login-card {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: 50px 100px;
    border: 1px solid #dddddd;
    background-color: white;;
    border-radius: 10px;
    overflow: hidden;
}

.login-form {
    width: 50%;
    padding: 20px 50px;
}

.login-card input {
    padding: 10px 20px;
    border-radius: 10px;
    border: 1px solid #dddddd;
    margin: 5px 0;
    width: 100%;
}

.login-card form {
    padding: 20px 50px;
}



.login-about {
    width: 50%;
    height: calc(100vh - 100px);
    background: linear-gradient(45deg, var(--secondary), var(--primary));
    color: white;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.login-about hr {
    width: 100%;
    border-color: #FFFFFF55;
    margin: 30px 0;
}

.forgot-password {
    width: 100%;
    color: var(--secondary);
    text-align: right;
}

.login-btn-primary {
    padding: 5px 20px;
    border-radius: 50px;
    background-color: var(--secondary);
    border: 1px solid var(--secondary);
    color: white;
    margin: 20px 10px;
    width: 100%;
}

.login-btn-secondary {
    padding: 5px 20px;
    border-radius: 50px;
    background-color: white;
    border: 1px solid var(--secondary);
    color: var(--secondary);
    margin: 20px 10px;
    width: 100%;
}

@media (max-width: 400px) {
    .login-card {
        margin: 50px 30px;
    }
    .login-about {
        display: none;
    }
    .login-card .login-form {
        width: 100%;
        padding: 10px 20px;
    }
}