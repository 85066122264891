/* Global */

:root {
  --accent: rgb(15, 61, 56);
  --primary: rgb(20, 40, 66);
  --secondary: #225577;
}

* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  background-color: #FAFAFA;
}

body {
  background-color: #FAFAFA;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  border-radius: 100px;
  border: 1px solid black;
}

button:hover {
  cursor: pointer;
}

hr {
  border: 0.5px solid var(--secondary);
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

/* Navigation */

nav {
  background-color: white;
  border: 1px solid #ddd;
  height: 64px;
  width: 100%;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
}

nav h2 {
  flex: 1;
}

.screen-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.sidebar {
  width: 350px;
  height: calc(100vh - 64px);
  background-color: white;
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px 40px;
  border-top: none;
  margin-top: 64px;
  position: static;
  overflow: hidden;
  transition: left ease 100ms;
}

.sidebar-link {
  margin: 5px 10px;
  padding: 2px 10px;
  border-radius: 30px;
  border: 1px solid transparent;
}

.sidebar .active {
  color: white;
  background-color: var(--secondary);
  margin: 5px 10px;
  padding: 5px 20px;
}

.sidebar-link:hover {
  border: 1px solid var(--secondary);
  color: var(--secondary);
  cursor: pointer;
}

.sidebar .active:hover {
  color: white;
}

.content {
  flex: 1;
  height: calc(100vh - 64px);
  padding: 30px;
  overflow-y: scroll;
  margin-top: 64px;
  display: flex;
  flex-direction: column;
}

.back-button {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  margin: 0 10px;
  color: #aaa;
  border: none;
  background-color: transparent;
}

.back-button:hover {
  background-color: #dddddd;
  color: black;
  cursor: pointer;
}

@media (max-width: 1000px) {
  .sidebar {
    width: 250px;
  }
}

@media (max-width: 900px) {
  .sidebar {
    width: 500px;
  }
}

@media (max-width: 900px) {
  .sidebar {
    position: fixed;
    width: 500px;
    left: -100%;
  }
}

@media (max-width: 600px) {
  .back-button {
    margin-left: 0;
  }
  .sidebar {
    position: fixed;
    width: 100%;
    left: -100%;
  }
  .content {
    padding: 15px;
  }
}



/* Loading */
.load-layout {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.load-container{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 5px;
  background: var(--secondary);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

/* Alert */
.alert-overlay {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #00000077;
  display: flex;
  justify-content: center;
  align-items: center;
}

.alert-dialog {
  background-color: white;
  border-radius: 10px;
  padding: 20px 40px;
  margin: 50px 30px;
  max-height: calc(100vh - 100px);
  max-width: 80%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.profile-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.profile-icon {
  background-color: lightgray;
  color: white;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: large;
}

.profile-text {
  margin-left: 20px;
  flex: 1;
}