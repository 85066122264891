.course-editor-card {
    margin: 20px 0;
}

.course-editor-card p {
    padding: 3px 10px;
}

.course-editor-card hr {
    margin: 20px 0;
}

.course-editor-field {
    min-width: 30%;
    max-width: 100%;
    border-radius: 10px;
    outline: none;
    border: 1px solid #aaa;
    padding: 5px 10px;
    margin: 0 5px;
}

.course-editor-button {
    width: 50%;
    padding: 5px 15px;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.course-editor-button:hover {
    cursor: pointer;
    background-color: var(--secondary);
    color: white;
}

.course-editor-upload {
    border: 1px solid black;
    border-radius: 10px;
    padding: 5px 15px;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.course-editor-upload:hover {
    cursor: pointer;
    background-color: var(--secondary);
    color: white;
}

.course-editor-upload:hover .material-icons {
    color: white;
}

.course-editor-upload-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #aaa;
    border-radius: 10px;;
}

.course-editor-file-name {

}