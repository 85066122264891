.user-sorting-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
}

.user-sorting-option {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 20px;
    border: 1px solid var(--primary);
    border-radius: 20px;
    margin: 2px 5px;
}

.user-sorting-option:hover {
    background-color: var(--primary);
    color: white;
    cursor: pointer;
}

.user-sorting-option .material-icons {
    font-size: medium;
    padding-left: 5px;
}

.user-list {
    flex: 1;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
}

.user-card {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px 20px;
    margin: 10px 0;
}

.user-card:hover {
    cursor: pointer;
    background-color: #eee;
}

.user-card-date {
    padding: 0 10px;
    color: gray;
}

.user-card-name {
    font-weight: bold;
}

.user-pagination-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.user-pagination-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    background-color: transparent;
}

.user-pagination-button:hover {
    border: 1px solid var(--secondary);
    color: var(--secondary);
    background: #eee;
}

.user-details-row {
    display: flex;
    flex-direction: row;
}

.assessment-summary {
    width: 100%;
    margin: 10px 20px;
    padding: 20px;
    background-color: white;
    border: 1px solid #dddddd;
    border-radius: 20px;
}

.assessment-summary-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
}

.assessment-summary-row button {
    background-color: transparent;
    padding: 0 20px;
    margin: 5px 0;
}

.assessment-summary-row button:hover {
    background-color: #dddddd;
}

.user-details {
    width: 100%;
    margin: 10px 20px;
    padding: 20px;
    background-color: white;
    border: 1px solid #dddddd;
    border-radius: 20px;
}

.user-details-item {
    margin: 10px 0;
}

.user-details-title {
    font-weight: bold;
}

@media (max-width: 700px) {
    .user-details-row {
        flex-direction: column;
        align-items: center;
    }
}