.report-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    margin: 30px 0;
}

.report-description {
    margin: 10px;
    color: #999999;
}

.report-card {
    background-color: white;
    border-radius: 10px;
    border: 1px solid #dddddd;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.report-card-score {
    text-align: center;
    width: 100%;
    font-size: 30px;
    padding: 50px;
    color: var(--secondary);
}

.report-card-description {
    color: #999999;
}

.report-card-rank {
    border: 1px solid var(--secondary);
    color: var(--secondary);
    padding: 5px 10px;
    border-radius: 10px;
    margin: 10px 0;
}

@media (max-width: 400px) {
    .report-grid {
        grid-template-columns: 1fr;
    }
    .report-card {
        padding: 20px;
    }
}