.course-tab-row {
    display: flex;
    flex-direction: row;
    margin: 20px 0;
}

.course-tab-item {
    padding: 7px 30px;
    border-radius: 20px;
    margin: 0 5px;
}

.course-tab-item:hover {
    background-color: #eee;
    cursor: pointer;
}

.course-tab-row .active {
    color: white;
    background-color: var(--secondary);
}

.course-list-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
}

.course-card {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    border: 1px solid #ddd;
    padding: 20px;
}

.course-card .material-icons {
    padding: 0 5px;
    color: var(--secondary)
}

.course-category {
    padding: 10px 15px;
    margin: 10px 0;
    border-radius: 50px;
    border: 1px solid #ddd;
    background-color: #eee;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: min-content;
    white-space: nowrap;
}

.course-category small {
    padding: 0 5px;
}

.course-list-grid .course-card:hover {
    cursor: pointer;
    background-color: #eee;
}

.course-card hr {
    margin: 10px 0;
}

.course-list-description {
    color: gray;
}

.course-content-picker {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    border: 1px solid #ddd;
    border-radius: 50px;
}

.course-content-picker .course-tab-item {
    margin: 0;
}

.course-content-picker .active {
    color: white;
    background-color: var(--secondary);
}

.course-nav-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
}

.course-button {
    padding: 5px 15px;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.course-button .material-icons {
    color: black;
    padding: 0;
}

.course-button p {
    padding: 0 5px;
}

.course-button:hover {
    cursor: pointer;
    background-color: var(--secondary);
    color: white;
}

.course-button:hover .material-icons {
    color: white;
}

.course-video {
    align-self: center;
    border-radius: 10px;
}

#pdf-canvas {
    align-self: center;
    border-radius: 10px;
}

.quiz-question-text {
    align-self: center;
    font-weight: bold;
}

.quiz-option-container {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
}

.quiz-answer {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px 20px;
    width: 100%;
}

.quiz-answer:hover {
    cursor: pointer;
    background-color: #eee;
}

.quiz-answer-selected {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px 20px;
    width: 100%;
    background-color: var(--secondary);
    color: white;
}

.course-info-action {
    font-weight: bold;
}

@media (max-width: 650px) {
    .course-list-grid {
        grid-template-columns: 1fr;
    }

    .quiz-option-container {
        grid-template-columns: 1fr;
    }
}

@media (min-width: 600px) {
    .course-info-action {
        width: 50%;
        align-self: center;
        padding: 10px 20px;
        margin: 20px;
    }

    .course-card {
        padding: 20px 40px;
    }
}