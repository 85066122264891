.questionnaire-progress-text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
}

.questionnaire-card {
    background: white;
    border: 1px solid #dddddd;
    border-radius: 10px;
    padding: 40px;
}

.questionnaire-options {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: 25px 0;
    width: 100%;
}

.questionnaire-answer {
    border: 1px solid var(--secondary);
    color: var(--secondary);
    width: 100%;
    padding: 5px 20px;
    border-radius: 30px;
    cursor: pointer;
    text-align: center;
}

.option-container {
    width: 100%;
    margin: 5px;
    display: flex;
}

.option-container input {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
}

.option-container input:checked ~ .questionnaire-answer {
    background-color: var(--secondary);
    color: white;
}

.questionnaire-bottom-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.next-button {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.next-button:hover {
    cursor: pointer;
}

.questionnaire-payment-dialog {
    max-width: 350px;
}

.questionnaire-payment-dialog p {
    color: #999999;
}

.questionnaire-payment-dialog hr {
    margin: 5px 0;
}

.questionnaire-payment-dialog button {
    padding: 5px 20px;
    color: white;
    background-color: var(--secondary);
}

.questionnaire-payment-dialog button:hover {
    background-color: var(--primary);
}

@media (max-width: 400px) {
    .questionnaire-options {
        flex-direction: column;
    }
}