.home-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
}

.home-card {
    border-radius: 10px;
    background-color: white;
    border: 1px solid #ddd;
    padding: 40px;
    margin: 10px 0;
}

.home-card:hover {
    background: linear-gradient(45deg, var(--secondary), var(--primary));
    cursor: pointer;
}

.home-card-disabled {
    border-radius: 10px;
    background-color: white;
    /*border: 1px solid #eee;*/
    padding: 40px;
    margin: 10px 0;
    color: #bbb;
}

.home-grid .icon {
    border-radius: 50%;
    height: 60px;
    width: 60px;
    border: 1px solid var(--secondary);
    color: var(--secondary);
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-card:hover .icon {
    border: 1px solid white;
    color: white;
}

.home-card p {
    color: #999;
}

.home-card:hover p {
    color: white;
}

.home-card:hover h3 {
    color: white;
}

.home-card:hover hr {
    border: 0.5px solid white;
}

@media (max-width: 650px) {
    .home-grid {
        grid-template-columns: 1fr;
    }
}